<script setup lang="ts">
  import { MpText } from '@borg/ui';

  const jobCard = useJobCard.inject();
</script>

<template>
  <MpText
    v-if="jobCard.job?.location"
    no-margin
  >
    {{ jobCard.job.location }}
  </MpText>
</template>
