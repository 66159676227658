<script setup lang="ts">
  import { MpText } from '@borg/ui';

  const jobCard = useJobCard.inject();
</script>

<template>
  <MpText
    v-if="jobCard.job?.userInfo.isNew"
    type="body2"
    no-margin
    semibold
    class="new-job"
  >
    {{ $t('JOB_CARD.NEW_JOB') }}
  </MpText>
</template>

<style scoped lang="scss">
  .new-job {
    --new-job-color: var(--mp-color-secondary-dark);

    width: fit-content;
    color: var(--new-job-color);
    padding: var(--mp-space-10) var(--mp-space-30);
    border: 1px solid var(--new-job-color);
    border-radius: var(--mp-space-20);

    @include dark-mode {
      --new-job-color: var(--mp-color-secondary-light);
    }
  }
</style>
