<script setup lang="ts">
  import { MpText } from '@borg/ui';

  const jobCard = useJobCard.inject();
</script>

<template>
  <MpText
    v-if="jobCard.organization"
    no-margin
    semibold
  >
    {{
      jobCard.organization === 'incognito'
        ? $t('ORGANIZATION_INCOGNITO')
        : jobCard.organization.name
    }}
  </MpText>
</template>
