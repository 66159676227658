<script setup lang="ts">
  import { MpText } from '@borg/ui';
  import { isUndefined } from '@borg/utils';

  const jobCard = useJobCard.inject();
  const userInfo = useUserInfo();

  const appliedAt = computed(() => {
    if (!jobCard.job) {
      return false;
    }
    const userInfoValue = userInfo.getApplied(jobCard.job.id);
    return !isUndefined(userInfoValue) ? userInfoValue : jobCard.job.userInfo.appliedAt;
  });
</script>

<template>
  <span
    v-if="appliedAt"
    :class="{ 'info-date': true, 'info-date--applied': true }"
  >
    <i18n-t
      keypath="JOB_CARD.APPLICATION_SENT_LABEL"
      tag="span"
      scope="global"
    >
      <template #date>
        <MpText
          as="time"
          no-margin
          :datetime="appliedAt"
          bold
        >
          {{ $d(...localeDateTimeFormat(appliedAt, 'dmy')) }}
        </MpText>
      </template>
    </i18n-t>
  </span>
  <MpText
    v-else-if="!jobCard.job?.isActive"
    type="body2"
    no-margin
    shade="30"
  >
    {{ $t('JOB_CARD.INACTIVE_LABEL') }}
  </MpText>
  <span
    v-else-if="jobCard.job?.endsAt"
    :class="{ 'info-date': true, 'info-date--end-date-near': jobCard.job?.isEndNear }"
  >
    <i18n-t
      keypath="JOB_CARD.ENDS_AT_LABEL"
      tag="span"
      scope="global"
    >
      <template #date>
        <MpText
          as="time"
          no-margin
          :datetime="jobCard.job.endsAt"
          bold
        >
          {{ $d(...localeDateTimeFormat(jobCard.job.endsAt, 'dmy')) }}
        </MpText>
      </template>
    </i18n-t>
  </span>
</template>

<style scoped lang="scss">
  .info-date {
    display: flex;
    align-items: center;
    gap: var(--mp-space-30);

    $applyColor: var(--mp-focus);
    $nearEndColor: var(--mp-color-status-warning);

    &--applied {
      color: $applyColor;
    }

    &--end-date-near {
      color: $nearEndColor;
    }
  }
</style>
